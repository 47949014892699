
































































































































































































@keyframes fadeIn {
  0%   { opacity: 0 }
  100% { opacity: 1 }
}

.vehicle-card-title
  display flex
  justify-content space-between

.vehicle > *
  animation: fadeIn .3s forwards
