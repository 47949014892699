.layout-padding {
  max-width: 768px !important;
  margin: auto;
}
span,
.hr {
  margin-top: 16px;
}
button {
  width: 100%;
}
.summary-main {
  padding: 20px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 33%);
  font-weight: 400;
  align-items: baseline;
}
.order-item {
  padding: 10px 0;
}
.grid > div {
  padding: 10px 0;
}
.grid > div:nth-child(2) {
  text-align: center;
}
.grid > div:nth-child(3) {
  text-align: right;
}
.description {
  font-weight: 700;
}
h6.text-bold {
  padding: 0 10px;
}
/*# sourceMappingURL=src/pages/ondemand/office-ride-hailing/summary.css.map */