.detail {
  font-size: 14px;
}
.vehicle {
  cursor: pointer;
}
.btn-price {
  color: #1e88e5;
  border-color: #1e88e5;
}
.vehicle:hover .btn-price {
  background: #1e88e5 !important;
  border-color: #1e88e5;
  color: #fff;
}
/*# sourceMappingURL=src/pages/ondemand/office-ride-hailing/vehicle-card.css.map */