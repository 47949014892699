



















































.detail
  font-size 14px

.vehicle
  cursor pointer

.btn-price
  color #1e88e5!important
  border-color #1e88e5!important

.vehicle:hover .btn-price
  background #1e88e5!important
  border-color #1e88e5!important
  color white!important
