
































































































































































.payment-buttons
  padding 16px
button
  width 100%
